import { firstValueFrom } from 'rxjs';
import {
	AssistantMessage,
	ProceduralMessage,
	RestoreMessages,
	UserMessage,
	UserProfileMessage
} from 'charlie-workflows/types';
import { useCallback } from 'react';

import { useHttp } from '../http/http.service';
import { IAnyMessage, SendMessageMetadata } from '../../types/messages.types';
import { ICreateChatArgs } from './messages-api.types';

export type UseMessagesApiHook = () => {
	sendMessage: (
		chatId: string,
		details: SendMessageMetadata
	) => Promise<{
		id: string;
		messages: (
			| AssistantMessage
			| RestoreMessages
			| UserMessage
			| UserProfileMessage
		)[];
	}>;
	createChat: (data: ICreateChatArgs) => Promise<{ id: string }>;
	getChatHistory: (chatId: string) => Promise<IAnyMessage[]>;
};

export const useMessagesApi: UseMessagesApiHook = () => {
	const { makeRequest } = useHttp();

	const sendMessage = useCallback(
		async (chatId: string, details: SendMessageMetadata) => {
			const formData = new FormData();
			formData.append('sessionId', chatId);
			formData.append(
				'message',
				JSON.stringify({
					type: details.messageType || 'userMessage',
					payload: {
						requestId: chatId,
						message: details.content,
						...(details.generativeButtons
							? { generativeButtons: details.generativeButtons }
							: {})
					}
				})
			);

			if (details.image) {
				formData.append('file', details.image);
			}

			return firstValueFrom(
				makeRequest<{
					id: string;
					messages: (AssistantMessage | RestoreMessages | UserMessage)[];
				}>(`/api/chat`, {
					method: 'POST',
					body: formData
				})
			);
		},
		[]
	);

	const createChat = useCallback(
		({
			topic,
			prompts,
			promptExperience,
			streamingProductCardCountExperience,
			recommendationsPromptEngineVersion,
			recipientId,
			randomPromptExperimentFeatureFlagVariant
		}: ICreateChatArgs): Promise<{ id: string }> => {
			const queryObject: Record<string, string> = {};

			if (topic) {
				queryObject['topic'] = topic;
			}

			if (promptExperience) {
				queryObject['promptExperience'] = promptExperience;
			}

			if (streamingProductCardCountExperience) {
				queryObject['streamingProductCardCountExperience'] =
					streamingProductCardCountExperience;
			}

			if (recommendationsPromptEngineVersion) {
				queryObject['recommendationsPromptEngineVersion'] =
					recommendationsPromptEngineVersion;
			}

			if (randomPromptExperimentFeatureFlagVariant) {
				queryObject['randomPromptExperimentFeatureFlagVariant'] =
					randomPromptExperimentFeatureFlagVariant;
			}

			if (recipientId) {
				queryObject['recipientId'] = recipientId;
			}

			const queryString = '?' + new URLSearchParams(queryObject).toString();

			return firstValueFrom(
				makeRequest<{ id: string }>(`/api/message${queryString}`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					...(prompts ? { body: JSON.stringify({ prompts }) } : null)
				})
			);
		},
		[]
	);

	const getChatHistory = useCallback(async (chatId: string) => {
		return firstValueFrom(
			makeRequest<IAnyMessage[]>(`/api/message/history/${chatId}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			})
		);
	}, []);

	return {
		sendMessage,
		createChat,
		getChatHistory
	};
};
