import { useState } from 'react';
import { useRouter } from 'next/router';
import { useFeatureFlagVariantKey } from 'posthog-js/react';
import { KnownStorageKeys, useStorage } from 'ollie-common/hooks/storage';
import {
  ICreateChatArgs,
  useMessagesApi,
} from 'ollie-common/hooks/messages-api';

export interface UseCreateChatHook {
  createChat: (
    initialMessage: string | undefined,
    data: ICreateChatArgs,
    options?: {
      chatUrl?: string;
      file?: File;
    },
  ) => void;
  isChatCreationLoading: boolean;
}

export const useCreateChat = (
  successCallback = () => {},
): UseCreateChatHook => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  const promptFeatureFlagVariant = useFeatureFlagVariantKey(
    'recommendation-prompt-v2',
  );
  const streamingProductCardCountFlagVariant = useFeatureFlagVariantKey(
    'product-cards-count',
  );
  const recommendationsPromptEngineVersionFeatureFlagVariant =
    useFeatureFlagVariantKey('recommendations-engine-experiment');
  const randomPromptExperimentFeatureFlagVariant = useFeatureFlagVariantKey(
    'prompt-a-b-experiment',
  );

  const { retrieve, store } = useStorage();
  const { createChat } = useMessagesApi();

  const handleMessageSubmit = (
    messageInput: string | undefined,
    args: ICreateChatArgs,
    options?: {
      chatUrl?: string;
      file?: File;
    },
  ) => {
    setIsLoading(true);

    createChat({
      promptExperience: promptFeatureFlagVariant
        ? String(promptFeatureFlagVariant)
        : undefined,
      streamingProductCardCountExperience: streamingProductCardCountFlagVariant
        ? String(streamingProductCardCountFlagVariant)
        : undefined,
      recommendationsPromptEngineVersion:
        recommendationsPromptEngineVersionFeatureFlagVariant
          ? String(recommendationsPromptEngineVersionFeatureFlagVariant)
          : undefined,
      randomPromptExperimentFeatureFlagVariant:
        randomPromptExperimentFeatureFlagVariant
          ? String(randomPromptExperimentFeatureFlagVariant)
          : undefined,
      ...args,
    }).then(({ id }) => {
      if (!id) {
        return;
      }

      successCallback?.();

      router.push(`${options?.chatUrl || '/chat'}/${id}`, undefined, {
        shallow: true,
      });

      if (messageInput && messageInput.length) {
        const existingInitialMessages = retrieve<Record<string, string[]>>(
          KnownStorageKeys.Messages,
        );
        store(KnownStorageKeys.Messages, {
          ...existingInitialMessages,
          [id]: [messageInput],
        });
      }

      // setIsLoading(false);
    });
  };

  return {
    createChat: handleMessageSubmit,
    isChatCreationLoading: isLoading,
  };
};
