import { Observable, map } from 'rxjs';
import { useCallback } from 'react';
import { useHttp } from '../http/http.service';
import { ExtendedRecipientInformation } from '../../types/recipients.types';
import { Recipient } from 'charlie-workflows/types';
import { RecipientEntity } from 'charlie-workflows/contracts';

export type UseRecipientsApiHook = () => {
	getRecipients: () => Observable<ExtendedRecipientInformation[]>;
	getRecipientById: (
		recipientId: string
	) => Observable<ExtendedRecipientInformation>;
	save: (
		recipient: Recipient,
		sessionToAttach?: string
	) => Observable<RecipientEntity>;
	update: (
		recipientId: string,
		metadata: Partial<Recipient>
	) => Observable<RecipientEntity>;
	delete: (recipientId: string) => Observable<void>;
};

export const useRecipientsApi: UseRecipientsApiHook = () => {
	const { makeRequest } = useHttp();

	const getRecipients = useCallback(() => {
		return makeRequest<{ recipients: ExtendedRecipientInformation[] }>(
			'/api/recipients'
		).pipe(map((result) => result.recipients));
	}, [makeRequest]);

	const getRecipientById = useCallback(
		(recipientId: string) => {
			return makeRequest<{ recipient: ExtendedRecipientInformation }>(
				`/api/recipients/${recipientId}`
			).pipe(map((result) => result.recipient));
		},
		[makeRequest]
	);

	const save = useCallback(
		(recipient: Recipient, sessionToAttach?: string) => {
			const body: Record<string, unknown> = { recipient };

			if (sessionToAttach) {
				body.sessionToAttach = sessionToAttach;
			}

			return makeRequest<{ recipient: RecipientEntity }>('/api/recipients', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(body)
			}).pipe(map((result) => result.recipient));
		},
		[makeRequest]
	);

	const update = useCallback(
		(recipientId: string, metadata: Partial<Recipient>) => {
			return makeRequest<RecipientEntity>(`/api/recipients/${recipientId}`, {
				method: 'PATCH',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(metadata)
			});
		},
		[makeRequest]
	);

	const deleteRecipient = useCallback(
		(recipientId: string) => {
			return makeRequest<void>(`/api/recipients/${recipientId}`, {
				method: 'DELETE'
			});
		},
		[makeRequest]
	);

	return {
		getRecipients,
		getRecipientById,
		save,
		update,
		delete: deleteRecipient
	};
};
