import { ReactNode, forwardRef } from 'react';

import styles from './input.module.scss';

type IInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  suffix?: ReactNode;
  prefix?: ReactNode;
  isError?: boolean;
  // errors?: Record<string, object>; TODO
};

export const Input = forwardRef<HTMLInputElement, IInputProps>(function Input(
  { className, suffix, prefix, isError, ...props },
  ref,
) {
  return (
    <div
      className={`${className || ''} ${styles.inputContainer} ${
        isError ? styles.error : ''
      } `}
    >
      {prefix}
      <input className={styles.input} type="text" ref={ref} {...props} />
      {suffix}
    </div>
  );
});
