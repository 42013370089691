/* eslint-disable @next/next/no-img-element */
import { Recipient } from 'charlie-workflows/types';
import Image from 'next/image';
import React, { MouseEvent, ReactNode, Ref, forwardRef } from 'react';
import { HiArrowRight } from 'react-icons/hi';
import { HiOutlineBookmark } from 'react-icons/hi';

import ProfileIcon from '@/public/assets/profile.svg';
import styles from './recipient-bubble.module.scss';

interface IRecipientBubbleParams {
  avatarSrc?: string;
  children?: ReactNode;
  attached?: boolean;
  className?: string;
  showArrow?: boolean;
  recipient?: Partial<Recipient>;
  savedProducts?: number;
  actionButtonTitle?: string;
  isReadonly?: boolean;

  onAction?: (e: MouseEvent) => void;
  ref?: Ref<HTMLDivElement>;
}

export const RecipientBubble: React.FC<IRecipientBubbleParams> = forwardRef<
  HTMLDivElement,
  IRecipientBubbleParams
>(function RecipientBubble(
  {
    recipient,
    children,
    className,
    attached,
    savedProducts,
    showArrow = false,
    isReadonly,
    onAction,
    actionButtonTitle,
  },
  ref,
) {
  return (
    <div ref={ref} className={`${styles.container} ${className || ''}`}>
      <div
        className={`${styles.avatar} avatar ${
          !attached ? styles.anonymous : ''
        }`}
      >
        <Image src={ProfileIcon} quality={100} alt="Profile" />
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.name}>{recipient?.name || ''}</div>
        {savedProducts ? (
          <div className={styles.savedProducts}>
            <HiOutlineBookmark></HiOutlineBookmark>

            <span>{savedProducts} ideas saved</span>
          </div>
        ) : null}

        {/* {recipient?.relationship ? (
          <div className={styles.relationship}>
            {recipient?.relationship}
            {recipient?.age ? ', ' + recipient.age : ''}
          </div>
        ) : null} */}

        {/* {recipient?.occasions?.length ? (
          <span className={styles.occasions}>
            {recipient?.occasions.map(({ name }) => name).join(', ')}
          </span>
        ) : null} */}

        {children}
      </div>

      <div className={styles.actionsContainer}>
        {!isReadonly && actionButtonTitle ? (
          <button className={`primary ${styles.saveButton}`} onClick={onAction}>
            {actionButtonTitle}
          </button>
        ) : null}
        {showArrow ? <HiArrowRight className={styles.rightIcon} /> : null}
      </div>
    </div>
  );
});
