import Switch from 'react-switch';
import { SyntheticEvent, forwardRef } from 'react';
import styles from './switcher.module.scss';

interface ISwitcherParams {
  className?: string;
  checked?: boolean;
  label?: string;
  onChange?: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string,
  ) => void;
}

export const Switcher: React.FC<ISwitcherParams> = forwardRef<
  HTMLInputElement,
  ISwitcherParams
>(function Switcher(
  { className, checked, label, onChange = () => {} },
  ref: any,
) {
  return (
    <label className={`${className} ${styles.container}`}>
      {label ? <span>{label}</span> : null}

      <Switch
        ref={ref}
        onChange={onChange}
        checked={Boolean(checked)}
        onColor="#ff6838"
        offColor="#E2DDD4"
        activeBoxShadow="0px 1px 2px rgba(0, 0, 0, 0.20)"
        uncheckedIcon={false}
        checkedIcon={false}
      />
    </label>
  );
});
