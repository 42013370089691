import { format as formatFns, getMonth, isDate } from 'date-fns';
import { LegacyRef, forwardRef, useEffect, useRef, useState } from 'react';
import { default as ReactDatepicker } from 'react-datepicker';
import styles from './datepicker.module.scss';
import { cutOffset } from '@/src/utils/common.utils';

interface IDatepickerProps {
  className?: string;
  value?: string | [string, string];
  disabled?: boolean;
  format?: string;
  error?: boolean;

  onBlur?: () => void;
  onChange?: (value: string | [string, string]) => void;
}

const renderDateFormat = 'MMM dd';
const outputDateFormat = 'yyyy-MM-dd';

export const Datepicker: React.FC<IDatepickerProps> = ({
  className,
  value,
  format,
  disabled,
  error,
  onBlur,
  onChange,
}) => {
  const [date, setDate] = useState<Date | null>(null);
  const [range, setRange] = useState<[Date | null, Date | null]>([null, null]);
  const isRange = Array.isArray(value);
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;

  const CustomInput = forwardRef(function CustomInput(
    { value, onClick, ...props }: { value?: string; onClick?: () => void },
    ref: LegacyRef<HTMLButtonElement>,
  ) {
    return (
      <button
        className={styles.input}
        onClick={(e) => {
          e.preventDefault();
          onClick?.();
        }}
        ref={ref}
      >
        {value || 'Enter date'}
      </button>
    );
  });

  useEffect(() => {
    if (isRange) {
      const [first, second] = value;

      setRange([
        first ? new Date(first) : null,
        second ? new Date(second) : null,
      ]);
    } else {
      setDate(value ? new Date(value) : null);
    }
  }, [value, isRange, setDate]);

  const handleDateChanges = (
    date: (Date | null) | [Date | null, Date | null],
  ) => {
    if (Array.isArray(date)) {
      onChange?.([
        date[0] ? formatFns(date[0], format || outputDateFormat) : '',
        date[1] ? formatFns(date[1], format || outputDateFormat) : '',
      ]);
    } else {
      onChange?.(date ? formatFns(date, format || outputDateFormat) : '');
    }
    onBlur?.();
  };

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <div className={`${styles.container} ${className || ''}`}>
      <ReactDatepicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              height: '2rem',
              justifyContent: 'center',
            }}
          >
            <button
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {'<'}
            </button>

            <select
              style={{ flex: 1 }}
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {'>'}
            </button>
          </div>
        )}
        disabled={disabled}
        selectsRange={isRange}
        selected={isRange ? undefined : cutOffset(date, timezoneOffset)}
        startDate={isRange ? cutOffset(range?.[0], timezoneOffset) : undefined}
        endDate={isRange ? cutOffset(range?.[1], timezoneOffset) : undefined}
        dateFormat={renderDateFormat}
        customInput={<CustomInput></CustomInput>}
        onChange={(date) => handleDateChanges(date)}
      />
    </div>
  );
};
